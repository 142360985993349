@import "../../styles/variables";
.ant-table-tbody > tr > td {
  background-color: #000;
  color: white;
}

.ant-table {
  background-color: #000;
}
.ant-table-tbody > tr.ant-table-row:hover > td {
  background: #333 !important;
}
.modal-order-history {
  .ant-modal-title {
    padding-top: 2.1rem;
    padding-bottom: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h3 {
      letter-spacing: 0.21px;
      line-height: 25px;
      font-size: 20px;
      color: $black-color;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .ant-tag-has-color {
        margin-left: 10px;
        font-size: 11px;
        font-weight: normal;
        letter-spacing: 0.12px;
        color: #ffffff;
        height: 22px;
        border-radius: 4px;
      }
    }

    h5 {
      font-size: 12px;
      line-height: 14px;
      color: $black-color;
      font-weight: normal;
      padding-top: 5px;
      display: flex;
      align-items: center;
      span {
        display: inline-block;
        height: 16px;
        width: 1px;
        background-color: #dcdcdc;
        margin: 0 7px;
      }
      b {
        font-weight: normal;
        color: $red-color;
      }
    }
  }
  .ant-modal-content {
    height: 750px;
    border-radius: 17px !important;
    padding-bottom: 17px;
  }
  .ant-modal-header {
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 17px 17px 0 0;
    border-bottom: none;
  }
  .ant-modal-body {
    padding-top: 0;
  }
  .modal-info {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #dcdcdc;
    border-top: 1px solid #dcdcdc;
    padding-top: 9px;
    padding-bottom: 15px;
    .modal-info-left {
      display: flex;
      justify-content: space-between;
      max-width: 49%;
      flex: 0 0 49%;
      border-right: 1px solid #dcdcdc;
      padding-right: 5px;
      .modal-info-left-text {
        display: flex;
        .modal-info-desc {
          padding-left: 10px;
          P {
            line-height: 17px;
            font-size: 14px;
            letter-spacing: 0.14px;
            color: $black-color;
            font-weight: bold;
          }
          .modal-info-time {
            color: $green-color-1;
            line-height: 17px;
          }
          span {
            font-size: 14px;
            letter-spacing: 0.14px;
            color: $black-color;
            line-height: 17px;
          }
        }
      }
      .button-time {
        width: 25%;
      }
    }
    .modal-info-right {
      max-width: 49%;
      flex: 0 0 49%;
      h5 {
        color: $black-color;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.14px;
        // padding-left: 5px;
        font-weight: bold;
      }
      p {
        text-align: left;
        color: $green-color-1;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.14px;
        b {
          text-align: left;
        }
        span {
          text-align: center;
        }
      }
    }
  }
  .modal-remark {
    padding: 12px 0;
    h3 {
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0.16px;
      color: $black-color;
      line-height: 19px;
      padding-bottom: 5px;
      span {
        padding-left: 11px;
        font-size: 14px;
        color: #0a8d87;
      }
    }
    h5 {
      letter-spacing: 0.14px;
      font-size: 14px;
      line-height: 18px;
      color: #2b2b2b;
    }
  }
  .ant-modal-footer {
    padding-bottom: 5px;
    padding-top: 5px;
    position: absolute;
    width: 100%;
    bottom: 5px;
    .ant-rate {
      color: "#D1D1D1";
    }
  }
}
.modal-revenue {
  .ant-modal-title {
    padding-top: 2.1rem;
    padding-bottom: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    h3 {
      letter-spacing: 0.21px;
      line-height: 25px;
      font-size: 20px;
      color: $black-color;
      font-weight: bold;
      font-size: 2rem;
      line-height: 25px;
    }
  }
  .ant-modal-content {
    height: 426px;
    border-radius: 17px !important;
    padding-bottom: 17px;
  }
  .ant-modal-header {
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 17px 17px 0 0;
    border-bottom: none;
  }
  .ant-modal-body {
    padding-top: 0;
  }
  .ant-modal-footer {
    padding-bottom: 5px;
    padding-top: 5px;
    position: absolute;
    width: 100%;
    bottom: 5px;
  }
  .ant-picker {
    border: none;
    .ant-picker-input {
      &:hover {
        border: none;
      }
      input {
        color: #4fa6ff;
        text-decoration: underline;
        font-weight: bolder;
      }
    }
  }
}
.modal-order-status {
  .ant-modal-title {
    padding-top: 2.1rem;
    padding-bottom: 1.2rem;
    display: flex;
    align-items: center;
    border-bottom: 2px solid #d4d4d4;
    h3 {
      letter-spacing: 0.21px;
      line-height: 25px;
      font-size: 20px;
      color: $black-color;
      font-weight: bold;
      font-size: 2rem;
      line-height: 25px;
    }
  }
  .ant-modal-content {
    height: 506px;
    border-radius: 17px !important;
    padding-bottom: 17px;
  }
  .ant-modal-header {
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 17px 17px 0 0;
    border-bottom: none;
  }
  .ant-modal-body {
    padding-top: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    padding-bottom: 0;
  }
  .ant-modal-footer {
    padding-bottom: 5px;
    padding-top: 5px;
    position: absolute;
    width: 100%;
    bottom: 5px;
  }
}
.order-status {
  margin-top: 15px;
  overflow-y: scroll;
  max-height: 300px;
  .order-status-title {
    letter-spacing: 0.21px;
    line-height: 25px;
    font-size: 20px;
    color: $black-color;
    font-weight: bold;
    font-size: 2rem;
    line-height: 25px;
    margin-bottom: 5px;
  }
  .order-status-timeline {
    letter-spacing: 0.21px;
    font-weight: 600;
    color: #646464;
  }
}

.body_history {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 80px);
}

.txtReasonCancel {
  color: #404040;
  font-size: 16px;
  font-weight: 500;
}

.blockReasonCancel {
  display: block;
  padding-right: 16px;
}

.contentOrderHistory {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.contentOrderHistory::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.ant-table-tbody > tr > td {
  border-bottom: 0.3px solid rgba(255, 255, 255, 0.25) !important;
  background-color: #0c111d !important;
}

.ant-table-thead > tr > th {
  border-bottom: none !important;
  background-color: #161b26 !important;
}

.ant-pagination-item,
.ant-pagination-item-active,
.ant-pagination-item-link,
.ant-pagination-item-ellipsis {
  background-color: #161b26 !important;
  color: #575757 !important;
  border-color: rgba(255, 255, 255, 0.25) !important;
}

.anticon-double-left,
.anticon-double-right {
  color: #575757 !important;
}

.ant-pagination-item a {
  color: #575757 !important;
}

.ant-pagination-item-active {
  background-color: #3d2d17 !important;
  border: 1px solid #cc9a05 !important;
}

.ant-pagination-item-active a {
  color: #cc9a05 !important;
}

.ant-pagination-options {
  display: none !important;
}

.header-bottom {
  // padding: 40px 0px;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .header-bottom-left {
    color: #f8cc3e;
    font-weight: 700;
    display: flex;
    align-items: center;
    font-size: 20px;
  }
  .header-bottom-right {
    display: flex;
    width: 45%;
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
      "Lucida Sans", Arial, sans-serif;
    justify-content: flex-end;
    .search-bar {
      width: 60%;
      .ant-input {
        padding: 5px 10px;
        text-indent: 5px;
        font-size: 12.6pt;
        border-radius: 5px;
        border: solid 1.5px #bdbdbd;
        // box-shadow: 0 0 5px 0.2px #D3D3D3;
      }
    }
    .search-bar input {
      box-shadow: none;
    }

    .search-bar:visited {
      outline: none !important;
      box-shadow: none !important;
    }
    .back-btn {
      display: flex;
      margin: 0 5px;
      border: none;
      border-radius: 5px;
      font-size: 18px;
      letter-spacing: 0.14px;
      background-color: #0a58ca;
      color: $white-color;
      height: 56px;
      padding: 16px 40px;
      align-items: center;
      font-weight: 600 !important;
      border-radius: 8px;
      font-family: "Montserrat", sans-serif;
    }
  }
}

.table-refund {
  .ant-table-thead {
    tr {
      th {
        background-color: $gray-color;
        color: $white-color;
        font-weight: bold;
        font-size: "14px";
      }
    }
  }
  .view-more {
    font-family: "Montserrat", sans-serif;
    color: #cc9a05;
    cursor: pointer;
    font-weight: 600;
    font-size: 16px;
  }
}

.refund-confirm-modal {
  top: 44% !important;
  padding: 50px;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 10px;
  .confirm-refund {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 2rem;
    line-height: 25px;
  }
  .ant-modal-confirm-btns {
    display: flex;
    width: 100%;
    flex-direction: column-reverse;
    .ant-btn-primary {
      background-color: #5d2af5;
      margin-bottom: 5px !important;
      border-radius: 5px;
      height: 45px;
    }
    .ant-btn-default {
      background-color: white;
      border: none;
      margin-bottom: 5px !important;
    }
  }
}
.loading_container {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.4);
}
