.customInput{
    height: 56px;
    width: 274px;
    background-color: transparent !important;
    border: none !important;
    color: #fff !important;
    font-size: 16px !important;

}

.container_customInput{
    border: 0.2px solid #3F3F3F;
    background-color: #161B26;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding-left: 16px;
    margin-right: 20px;
}