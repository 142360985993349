$yallow-color: #FFC522;
$black-color: #1B1B1B;
$gray-color:#414141;
$red-color: #E31837;
$white-color: #FFFFFF;
$orange-color:#F0810D;
$green-color-1:#0A8D87;
$green-color-2:#00B77F;
$green-color-3:#3FB4C3;
$green:#55C04A;
$blue:#47619b;
$bg-columns:#F8F8F8;
$orange-color-2:#FF9D00;
$green-color-4:#198754;
@mixin flex-center {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

