.container-wrap {
    color: #333;
    background: #F0F0F0;
    width: 100%;
    height: 100%;
    padding: 16px;
}

.header-wrap {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.header img {
    width: 26px;
    height: 25px;
}

.settings-title {
    color: #FF9D00;
    font-weight: 500;
    margin-left: 8px;
    font-size: 20px;
}

.kitchen-container {
    width: 100%;
    height: 100%;
    background: #FFFDF5;
    padding: 16px 30px;
    border-radius: 8px;
}

.station-display {
    display: flex;
    align-items: center;
}

.station-display h4 {
    color: #333;
    font-weight: 600;
    font-size: 20px;
}

.station-display img {
    width: 26px;
    height: 25px;
    margin-left: 16px;
}

.kitchen-station-label {
    color: #333;
    font-size: 16px;
    font-weight: 500;
    margin-top: 12px;
}

.button-container {
    display: flex;
    align-items: center;
    margin-top: 40px;
}

.button-kitchen {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 140px;
    height: 50px;
    border: 1px solid #333;
    background: white;
    border-radius: 8px;
    color: #333;
    font-weight: 600;
    cursor: pointer;
}

.button-kitchen.save {
    background: #FF9D00;
    color: #fff;
    margin-left: 16px;
    border: none;
}

.itemSelected {
    border: 1px solid #FF9D00;
    margin-right: 16px;
    padding: 8px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.imgSelected{
    cursor: pointer;
    width: 25px;
    height: 25px;
    margin-left: 8px;
}

.selectKitchenSetting .ant-select-selector {
    height: 35px !important; /* Tùy chỉnh chiều cao */
  }

  .loading{
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.4);
  }
