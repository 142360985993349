.login-form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  // background: red;
  .login-top {
    height: 'auto';
    background: #000;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    .login-box {
      background: #1F242F;
      padding: 16px;
      border-radius: 16px;
      
      .err {
        span {
          display: inline-block;
          width: 367px;
          text-align: left;
        }
      }
      .ant-form-item {
        &:nth-child(3) {
          margin-bottom: 10px;
        }
      }
      .ant-form-item-explain-error {
        color: #ffffff;
      }
      .login-input {
        width: 367px;
        height: 54px;
        background: #191919 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        opacity: 1;
        border-radius: 8px;
        border: 0;
        margin-top: 8px;
        color: white ;
        .ant-input{
          color: white;
        }
      }
      .ant-input-password{
        .ant-input{
          background: transparent;
        }
      }
      .ant-input-password-icon{
        color: #000;
        font-size: 20px;
      }
      .login-input-light {
        width: 367px;
        height: 54px;
        background: #EAECF0 0% 0% no-repeat padding-box;
        box-shadow: 0px;  
        opacity: 1;
        border-radius: 8px;
        border: 0;
        margin-top: 8px;
        color: #000 !important;
        .ant-input{
          color: #000 !important;
        }
      }
    }
  }
  
    
    .login-button-container {
      .login-button {
        background: #F8CC3E 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 9px;
        opacity: 1;
        width: 367px;
        height: 58px;
        outline: none;
        border: none;
        text-align: center;
        font-family: sans-serif;
        font-weight: 600;
        letter-spacing: 0.16px;
        color: #000;
        opacity: 1;
      }
    }
  }


.login_logo{
  filter: invert(100%);
}
.header-title{
  display: flex;
  align-items: center;
  h3 {
  font-weight: bold;
font-size: 16px;
line-height: 24px;
    letter-spacing: 0.16px;
    color: #F8CC3E;
    font-weight: 600;
    font-size: 34px;
  }
  margin-bottom: 30px;
}

.light {
  background: #f0f0f0 !important;
  color : black !important;
}
.box-light {
  background: #F8F9FC !important;
  border: 1px solid #E1E6EF !important;
}

.input-name-text{
  font-weight: 400;
  font-size: 17px;
  color: white;
}
.lightText{
  color: black !important;
}
.ant-checkbox-checked .ant-checkbox-inner{
  background-color: #F8CC3E !important;
  border-color: #F8CC3E !important;
}