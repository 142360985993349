@import "/src/styles/index.scss";

.home-page-light {
  background: #f0f0f0;
  height: 100vh;
}

.board-wrapper {
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
  overflow-x: auto;
  // flex-wrap: nowrap;
  .board-columns {
    max-width: 31%;
    // width: 422px;
    flex: 0 0 31%;
    background-color: $red-color;
    height: calc(100vh - 135px);
    background-color: $bg-columns;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    overflow: hidden;
    .columns-header {
      height: 50px;
      padding: 14px 16px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .columns-header-left {
        display: flex;
        align-items: center;
        width: 100%;

        .columns-header-title {
          font-weight: bold;
          font-size: 20px;
          line-height: 24px;
          letter-spacing: 0.14px;
          color: $white-color;
          font-weight: 700;
          padding: 0 10px;
          flex-grow: 1;
        }
        .columns-header-count {
          text-align: center;
          line-height: 20px;
          color: $white-color;
          font-size: 20px;
          border-radius: 100%;
          font-weight: 700;
          margin-left: auto;
        }
      }
      .colums-header-right {
        .btn-print-order {
          border: 1px solid #ffffff;
          border-radius: 6px;
          padding: 3px 10px;
          // width: 73px;
          // height: 25px;
          font-size: 14px;
          color: $white-color;
          background: transparent;
        }
      }
    }
    &:nth-child(1) .columns-header {
      background-color: #1677ff;
    }
    &:nth-child(2) .columns-header {
      background-color: #ff9d00;
    }
    &:nth-child(3) .columns-header {
      background-color: #112A5A;
    }
    &:nth-child(4) .columns-header {
      background-color: $green-color-3;
    }
    &:nth-child(5) .columns-header {
      background-color: $green-color-2;
    }
    .colums-wrapper {
      padding: 11px 8px;
      height: 100%;
      overflow-y: auto;
      padding-bottom: 50px;
      &::-webkit-scrollbar {
        width: 0px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 20px;
        background-color: transparent;
        background-clip: content-box;
      }
      background: #1f1f1f;
    }
    .colums-wrapper-light {
      padding: 11px 0;
      height: 100%;
      overflow-y: auto;
      padding-bottom: 50px;
      &::-webkit-scrollbar {
        width: 0px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 20px;
        background-color: transparent;
        background-clip: content-box;
      }
      background: #f0f0f0;
    }
  }
}

.loading_container {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.4);
}
.type-list {
  display: flex;
  padding-inline: 20px;
  gap: 20px;
  flex-wrap: wrap;
}
.type-item {
  color: #ffffff;
  padding-inline: 14px;
  height: 52px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 4px;
  border: 1px solid #f5f5f6;
  gap: 16px;
  cursor: pointer;
}
.type-item-count {
  background: #f8cc3e;
  border-radius: 100px;
  height: 20px;
  width: 20px;
  text-align: center;
  line-height: 20px;
}
.type-item-active {
  border: 1.2px solid var(--Primary-Orange, #f8cc3e);
  background: rgba(255, 157, 0, 0.2);
  color: #f8cc3e;
  .type-item-count {
    color: white;
  }
  font-weight: 600;
}
